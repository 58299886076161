import { createSelector }  from 'reselect'

const Category = state => state.category

export const categoryMessageSelector = createSelector(
    [Category],
    (category)=>category.success || category.error 
) 

export const categoryUpdateSelector = createSelector(
    [Category],
    (category)=>category.updateID
) 

export const categoryListSelector = createSelector(
    [Category],
    (category)=>category.data
) 

export const categoryToggleSelector = createSelector(
    [Category],
    (category)=>category.toggle
) 

export const categoryloadingSelector = createSelector(
    [Category],
    (category)=>category.loading
) 

export const categoryErrorSelector = createSelector(
    [Category],
    (category)=>category.error
) 

