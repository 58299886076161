import * as actionType from "./user.actionType";

const initialState = {
  username: "",
  password: "",
  token: "",
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_USERNAME:
      return {
        ...state,
        username: action.payload,
      };
    case actionType.SET_PASSWORD:
      return {
        ...state,
        password: action.payload,
      };

    case actionType.SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
  }
  return state;
};
