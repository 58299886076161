import * as actionType from "./meme.type";

const initialState = {
  loading: false,
  data: [],
  success: null,
  loadingError: null,
  error: null,
  updateID: null,
  toggle: false,
  trendingMeme: [],
  meme: {},
};

export const memeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.MEME_LIST_LOADING:
      return {
        ...state,
        loading: true,
        loadingError: null,
        updateID: null,
      };
    case actionType.UPDATE_MEME:
      return {
        ...state,
        updateID: action.payload,
        toggle: !state.toggle,
      };
    case actionType.MEME_LIST_SUCCESS:
      return {
        ...state,
        loadingError: false,
        data: action.payload,
      };
    case actionType.MEME_LIST_FAILED:
      return {
        ...state,
        loadingError: false,
        // data: [],
        error: action.payload,
      };
    case actionType.MEME_MSG:
      return {
        ...state,
        success: action.payload,
        toggle: !state.toggle,
      };
    case actionType.MEME_ADDING_ERROR:
      return {
        ...state,
        error: action.payload,
        toggle: !state.toggle,
      };
    case "CLEAR_MEME_MSG":
      return {
        ...state,
        success: null,
        error: null,
      };

    case actionType.SET_TRENDING_MEME:
      return {
        ...state,
        trendingMeme: action.payload
      };
      case actionType.SET_MEME:
        return {
          ...state,
          meme: action.payload
        };
        case actionType.GET_MEME:
          return {
            ...state,
            // meme: action.payload
          };
  }
  return state;
};
