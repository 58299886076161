import React from 'react'
import { Modal, Button } from 'react-bootstrap'


const Alert = ({ open, close, confirm, message, type }) => {
    return (
        open ?
            <div className="alert-container" >
                <div style={{ width: '400px', maxWidth: '100%' }} >
                    <Modal.Dialog>
                        <Modal.Body>
                            <p className="text-center"><strong>{message}</strong></p>
                        </Modal.Body>
                        <div className="alert-btn" >
                            {type === "alert" ?
                                <Button variant="warning" onClick={close} >Close</Button>
                                :
                                <>
                                    <Button variant="danger" onClick={close} >Cancel</Button>
                                    <Button variant="success" onClick={confirm} >Confirm</Button>
                                </>
                            }
                        </div>
                    </Modal.Dialog>
                </div>
            </div>
            : null
    );
}
export default Alert