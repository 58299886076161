import React, { useEffect, useState } from 'react'
import { Tabs, Tab, Row, Col, Container, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { fetchCategory, deleteCategory, togleCategory } from '../redux/category/category.action'
import { categoryListSelector, categoryToggleSelector } from '../redux/category/category.selector'
import CategoryTable from '../Components/Table/category.table'
import { CategoryForm } from '../containers/Category.container'
import Alert from '../Components/Alert/alert.component'

const Category = () => {
    const [deletId, setDeleteId] = useState(null)
    const Dispatch = useDispatch()
    const categoryList = useSelector(state => categoryListSelector(state))
    const categoryToggle = useSelector(state => categoryToggleSelector(state))

    useEffect(() => {
        Dispatch(fetchCategory())
    }, [])

    const editHandler = (id) => {
        Dispatch({ type: 'UPDATE_CATEGORY', payload: id })
    }
    const deleteHandler = (id) => {
        setDeleteId(id)
        // Dispatch(deleteCategory(id))
    }
    return (
        // <Container>
        <>
            <Row className="p-2" style={{background: 'black'}}>
                <Col className="mr-3">
                    <h5 className="text-white">Category</h5>
                </Col>
                <Col md="2">
                    <Button variant="warning" onClick={() => Dispatch(togleCategory())} >Add Category</Button>
                </Col>
            </Row>
            <CategoryTable tableData={categoryList} onEdit={editHandler} OnDelete={deleteHandler} />
            <CategoryForm show={categoryToggle} onClose={() => Dispatch(togleCategory())} />
            <Alert open={deletId} message={"Do You want to delete Category ! deleting category will delete memes in this category"}
                close={() => setDeleteId(null)} confirm={() => {
                    Dispatch(deleteCategory(deletId))
                    setDeleteId(null)
                }} />
        </>
        // </Container>
    );
}
export default Category