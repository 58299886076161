import { createSelector }  from 'reselect'

const Meme = state => state.meme

export const memeMessageSelector = createSelector(
    [Meme],
    (meme)=>meme.success || meme.error 
) 

export const memeUpdateSelector = createSelector(
    [Meme],
    (meme)=>meme.updateID
) 

export const memeListSelector = createSelector(
    [Meme],
    (meme)=>meme.data
) 

export const memeToggleSelector = createSelector(
    [Meme],
    (meme)=>meme.toggle
) 

export const memeloadingSelector = createSelector(
    [Meme],
    (meme)=>meme.loading
) 

export const memeErrorSelector = createSelector(
    [Meme],
    (meme)=>meme.error
) 

