import React from 'react'
import DataTable,{createTheme} from 'react-data-table-component';
import { Button } from 'react-bootstrap'



const CategoryTable = ({ tableData, onEdit, OnDelete }) => {
    let columns = []
    let key = tableData.length > 0 ? Object.keys(tableData[0]) : []
    console.log('key', key)
    if (key.length > 0) {
        key.map(list => {
            columns.push({
                name: list.toUpperCase(),
                selector: list
            })

        })
    }

    columns.push({
        name: 'Action',
        grow: 1,
        cell: row => (<div style={{ width: '100%', display: "flex", flexFlow: 'row', justifyContent: 'space-around' }} >
            <Button variant="warning" onClick={() => onEdit(row.id)} > Edit</Button>
            <Button  variant="danger" onClick={() => OnDelete(row.id)}  > Delete</Button>
        </div>)
    })

    createTheme('solarized', {
        text: {
          primary: 'rgba(0,0,0)',
          secondary: '#fff',
        },
        background: {
        //   default: '#002b36',
        },
        context: {
          background: '#cb4b16',
          text: '#FFFFFF',
        },
        divider: {
          default: 'rgba(0,0,0,.7)',
        },
        action: {
          button: 'rgba(0,0,0,.54)',
          hover: 'rgba(0,0,0,.08)',
          disabled: 'rgba(0,0,0,.12)',
        },
      });

    return (
        <DataTable
            columns={columns}
            data={tableData}
            // dense={true}
            striped={true}
            theme= "solarized"
            pagination={true}
            responsive={true}
        />
    )
}

export default CategoryTable