import { combineReducers } from 'redux';
import { userReducer } from '../redux/user/user.reducer'
import { categoryReducer } from '../redux/category/category.reducer'
import { memeReducer } from '../redux/meme/meme.reducer'

const rootReducer = combineReducers({
    userReducer: userReducer,
    category: categoryReducer,
    meme: memeReducer

})

export default rootReducer
