import * as actionType from "./user.actionType";
import axios from "axios";

export const setUsername = (data) => ({
  type: actionType.SET_USERNAME,
  payload: data,
});

export const setPassword = (data) => ({
  type: actionType.SET_PASSWORD,
  payload: data,
});

export const setToken = (data) => ({
  type: actionType.SET_TOKEN,
  payload: data,
});

export const login = (username,password) => {
  console.log("Logging in....");

  const authdata = {
    username: username,
    password: password,
  };

  return (dispatch) => {
    axios.post('/authentication/admin-login',authdata)
    .then((resp) => {
        console.log('RES',resp.data);
        dispatch(setToken(resp.data.access))
        if(resp.data.access){
          localStorage.setItem('token',resp.data.access)
        }
        
    }).catch((error) => {
      return alert('Invalid Credientials!!')
    })
  };

};

export const logOut = () => {
    console.log("Logging Out....");
     return dispatch => {
      //  localStorage.clear('token')
         localStorage.clear()
         dispatch(setToken(''))
        
     }
   };
  