import React from 'react'
import { Modal, Button } from 'react-bootstrap'

const ModalComp = ({ show, onHide, heading, children }) => {
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="modal-title-vcenter"
            centered
        >
            <div>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {heading}
                    </Modal.Title>
                </Modal.Header>
                <i onClick={onHide} class="fa fa-times" style={{
                    position: 'absolute',
                    top: '25px',
                    right: '15px',
                }} aria-hidden="true"></i>
            </div>
            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal>
    );
}
export default ModalComp
