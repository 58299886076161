import React from 'react'
import { Row, Col } from 'react-bootstrap'
import {NavLink} from 'react-router-dom'
import {logOut, setToken} from '../redux/user/user.action'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import { memeListSelector } from '../redux/meme/meme.selector'
import IMG from "../images/logo.png"

function Sidebar() {
  const dispatch = useDispatch()
  const history = useHistory()
  const memeList = useSelector(state => memeListSelector(state))
  let filtered = memeList.filter(meme => meme.is_enable == false)

  return (
    <Row>
      <Col md="3" lg="2" className="p-3 sidebar" >
        <Row>
         <div className="logo-container"><img src={IMG} className="logo"></img></div>
          </Row>
        <NavLink exact to="/" className="nav-btn" activeClassName="nav-btn-active" >Dashboard</NavLink>
        <NavLink to="/cat" className="nav-btn" activeClassName="nav-btn-active" >Category</NavLink>
        <NavLink to="/meme" className="nav-btn" activeClassName="nav-btn-active" >All Memes</NavLink>
        <NavLink to="/pending" className="nav-btn" activeClassName="nav-btn-active">Pending Memes <span class="badge">{filtered && filtered.length !== 0 && filtered.length}</span></NavLink>
        <NavLink to="/change_password" className="nav-btn" activeClassName="nav-btn-active" >Change Password</NavLink>
        <a exact style={{cursor: 'pointer'}} className="nav-btn" activeClassName="nav-btn-active" onClick={()=> {
             dispatch(logOut())
             history.push('/')
        }}>Logout</a>
        {/* <NavLink to="/pending" className="nav-btn" activeClassName="nav-btn-active" >Pending Aproval</NavLink> */}
      </Col>
    </Row>

  )
}

export default Sidebar
