

import React,{ useEffect, useState} from 'react'
import DataTable from 'react-data-table-component';
import { Button } from 'react-bootstrap'
import { BsJustify } from 'react-icons/bs';
import { updateMeme } from '../../redux/meme/meme.action';
import {useDispatch, useSelector} from 'react-redux'
import { MAINURL } from '../..';
import { Link } from 'react-router-dom';

const MemeTable = ({ tableData, onEdit, OnDelete }) => {

    const dispatch = useDispatch()
    const {token} = useSelector(state => state.userReducer)

    let columns = []
    let key = tableData.length > 0 ? Object.keys(tableData[0]) : []

      console.log('Table Dtaa',tableData);

    if (key.length > 0) {
        key.map(list => {
            if (list !== "category" && list !== "is_enable" && list !== "descriptions" && list !== "updated_at"&& list !== "image" && list !== "user_id" && list !== "tags") {
                columns.push({
                    name: list.toUpperCase(),
                    selector: list
                })
            }
             else if (list == "image") {
                columns.push({
                    name: list.toUpperCase(),
                    cell: row => <img height="60"  width="60" src={`${MAINURL}${row.image}`} />
                    
                })
            }
        })
    }

    // const handleChange = (e) => {
    //      dispatch(updateMeme())
    // }

    columns.push({
        name: 'Enable',
        grow: 1,
        // width: '130px',
        cell: row => (<div class="form-check form-switch">
        <input class="form-check-input bg-warning" type="checkbox" id="flexSwitchCheckChecked" defaultChecked={row.is_enable} onChange={(e)=>{
            
            console.log('Valuee',e.target);
            dispatch(updateMeme(row.id,{is_enable: !row.is_enable},token))
        }}></input>
      </div>)
    })

    columns.push({
        name: 'View',
        cell: row =>  <Link className="btn btn-warning" to={`/view-meme/${row.id}`}> View</Link>
        
    })

    columns.push({
        name: 'Action',
        grow: 1,
        width: '200px',
        cell: row => (<div style={{ width: '100%', display: "flex", flexFlow: 'row', justifyContent: 'space-around' }} >
            <Button variant="warning" onClick={() => onEdit(row.id)} > Edit</Button>
            <Button variant="danger" onClick={() => OnDelete(row.id)}  > Delete</Button>
        </div>)
    })

    return (
        <DataTable
            columns={columns}
            data={tableData}
            dense={true}
            striped={true}
            responsive={true}
            pagination={true}

        />
    )
}

export default MemeTable