import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CKEditor from "ckeditor4-react";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { createMeme, updateMeme } from "../redux/meme/meme.action";
import { memeMessageSelector } from "../redux/meme/meme.selector";
import { categoryListSelector } from "../redux/category/category.selector";
import { fetchCategory } from "../redux/category/category.action";

import Alert from "../Components/Alert/alert.component";
import { memeListSelector } from "../redux/meme/meme.selector";
import { useParams } from "react-router";

export const EditMeme = ({ history }) => {
  const [title, setTitle] = useState("");
  const [titleErr, settitleErr] = useState();

  const [data, setData] = useState("");
  const [dataError, setDataError] = useState("");

  const [file, setFile] = useState("");
  const [fileErr, setfileErr] = useState();

  const [author, setAuthor] = useState("");
  const [authorErr, setauthorErr] = useState("");

  const [category, setCategory] = useState("");
  const [catErr, setcatErr] = useState("");

  const [totalSum, setTotalSum] = useState("");
  const [totalRequired, settotalRequired] = useState("");
  const [totalSumError, setTotalSumError] = useState("");

  const [tags, setTags] = useState([]);
  const [tagsError, setTagsError] = useState("");



  const Dispatch = useDispatch();
  const categoryList = useSelector((state) => categoryListSelector(state));
  const { token } = useSelector(state => state.userReducer)
  const memeMSG = useSelector((state) => memeMessageSelector(state));



  let { id } = useParams();

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      title !== "" &&
      //   file !== "" &&
      category !== "" &&

      data !== "" &&
      tags.length !== 0 &&
      author !== ""
    ) {
      let save = {
        id,
        title,
        file,
        category,
        author,
        descriptions: data,
        tags,
      };


      Dispatch(updateMeme(id, save));


    } else {
      if (!data) {
        setDataError("Description is required!");
      }
      if (tags.length == 0) {
        setTagsError("Tag is required");
      }
      if (!file) {
        setfileErr("File is required!");
      }

      if (!title) {
        settitleErr("Title is required!");
      }
      if (!category) {
        setcatErr("Category is required");
      }
      if (!author) {
        setauthorErr("Author is required!");
      }

    }
  };

  const handleFileChnage = (e) => {
    setFile(e.target.files[0]);
  };

  // useEffect(() => {
  //   setSum(numA + numB);
  // }, [numA, numB]);

  const handleChange = (tag) => {
    setTags(tag);
  };

  const memeList = useSelector((state) => memeListSelector(state));


  useEffect(() => {
    Dispatch(fetchCategory(1))
  }, [])

  useEffect(() => {
    if (id && memeList.length > 0) {
      const meme = memeList.find((meme) => meme.id == id);
      console.log("MEME", meme);
      setTitle(meme.title);
      setTimeout(() => (
        setData(`${meme.descriptions}`)
      ), 500)
      var array = meme.tags.split(',');
      setTags(array)
      console.log(meme.tags);
      setFile(meme.image);
      setCategory(meme.category.id);
      setAuthor(meme.author_name);

    }
  }, [id, memeList]);

  return (
    <>
      <div className="container">
        <div className="row">
          <form className="col-md-8 col-xs-12" onSubmit={onSubmit}>
            <div className="upload-title-box">
              <h1 className="upload-title">Upload</h1>
            </div>
            <div className="upload-form-wrapper">
              <h2>meme upload tool</h2>
            </div>
            <div className="col form-input-wraper">
              <label>Post title</label>
              <input
                className="form-control"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              {titleErr && <span className="error">* {titleErr}</span>}
            </div>

            <div className="col form-input-wraper">
              <label>
                Leave this blank by only typing an empty space. Feature to be
                tested.
              </label>
              <CKEditor
                onChange={(e) => setData(e.editor.getData())}
                data={data}
                required
              />
              {dataError && <span className="error">* {dataError}</span>}
            </div>
            <div className="col form-input-wraper">
              <label>
                This is the actual meme that shows up in images on the website.
              </label>
              <input type="file" onChange={handleFileChnage} />
              {/* {fileErr && <span className="error">* {fileErr}</span>} */}
            </div>
            <div className="col form-input-wraper">
              <label>Category</label>
              <select
                className="form-select"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option defaultValue={null}>Uncategorizes</option>
                {categoryList.map((list, indx) => (
                  <option key={indx} value={list.id}>
                    {list.name}
                  </option>
                ))}
              </select>
              {catErr && <span className="error">* {catErr}</span>}
            </div>
            <div className="col form-input-wraper">
              <label>Tags (Use " Tab " to add multiple tags)</label>
              <TagsInput
                className="form-control tag-input"
                focusedClassName="focus-tag"
                value={tags}
                onChange={handleChange}
              />
              {tagsError && <span className="error">* {tagsError}</span>}
            </div>
            <div className="col form-input-wraper">
              <label>Author Name</label>
              <input
                className="form-control"
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
              />
              {authorErr && <span className="error">* {authorErr}</span>}
            </div>
            {/* <div className="col form-input-wraper">
              <label>Human Check</label>
              <div className="d-flex align-items-center">
                <p className="pt-1 m-0">
                  {numA} + {numB} ={" "}
                </p>
                <input
                  placeholder="Enter Sum"
                  className="form-control human-check"
                  value={totalSum}
                  onChange={sumValidate}
                />
              </div>
              {totalSumError ? <span>{totalSumError}</span> : null}
              {totalRequired && !totalSumError && (
                <span className="error">* {totalRequired}</span>
              )}
            </div> */}
            <button type="submit" className="post-btn">
              submit post
            </button>
          </form>
          <div className="col-md-4 col-xs-12">
            <div className="promotion">
              <img src="http://memeworld.co.za/wp-content/uploads/2020/09/advert2.png" />
            </div>
            {/* <div className="promotion p-2" >
                        <div className="promo-title">
                            <p>Recent memes</p>
                        </div>
                    </div> */}
          </div>
        </div>
      </div>
      <Alert
        type="alert"
        open={memeMSG}
        message={memeMSG}
        close={() => {
          Dispatch({ type: "CLEAR_MEME_MSG" });
          history.goBack();
        }}
      />
    </>
  );
};
