import React, { useEffect } from 'react';
import "./App.css";
import { Login } from "./containers/Login";
import { Row, Col } from 'react-bootstrap'
import {
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SideBar from './Components/Sidebar'
import Dashboard from "./pages/Dashboard"
import Category from './pages/Category'
import Meme  from "./pages/meme";
import {Upload} from './pages/upload'
import {EditMeme} from './pages/EditMeme'
import Pending from "./pages/Pending"
import { setToken } from './redux/user/user.action';
import { fetchMeme } from './redux/meme/meme.action';
import { fetchCategory } from './redux/category/category.action';
import ViewMeme from './pages/ViewMeme';
import { ChangePassword } from "./pages/changePassword";
function App() {
  const dispatch = useDispatch()
  const { token } = useSelector((state) => state.userReducer);
  console.log("TOKEN", token);

  // useEffect(() => {
  //   !token? <Redirect to="/dashboard"></Redirect> :  <Redirect to="/login"></Redirect>
  // }, [token])
  useEffect(() => {
    let foundedToken = localStorage.getItem('token')
    if(foundedToken) {
        dispatch(setToken(foundedToken))
         
    }
    dispatch(fetchMeme())
    dispatch(fetchCategory())
  },[])



  return (
    <Switch>
      {!token ?
        <Route exact path="/" component={Login} />
        :
        <div className="d-flex flex-row">
          <Col md="3" lg="2">
            <SideBar />
          </Col>
          <Col md="9" lg="10">
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/cat" component={Category} />
            <Route exact path="/meme" component={Meme} />
            <Route exact path="/upload-meme" component={Upload} />
            <Route exact path="/pending" component={Pending} />
            <Route exact path="/edit-meme/:id" component={EditMeme} />
            <Route exact path="/view-meme/:id" component={ViewMeme} />
            <Route exact path="/change_password" component={ChangePassword} />
          </Col>
        </div>
      }
    </Switch >
  );
}

export default App;
