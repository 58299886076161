import axios from "axios";
import * as actionType from "./meme.type";

const setMemeLoading = () => ({
  type: actionType.MEME_LIST_LOADING,
});

const setMemeSuccess = (data) => ({
  type: actionType.MEME_LIST_SUCCESS,
  payload: data,
});

const setMemeFailed = (error) => ({
  type: actionType.MEME_LIST_FAILED,
  payload: error,
});

const setMemeMSG = (msg) => ({
  type: actionType.MEME_MSG,
  payload: msg,
});

const setMemeAddingFailed = (error) => ({
  type: actionType.MEME_ADDING_ERROR,
  payload: error,
});

const setTrendingMeme = (data) => ({
  type: actionType.SET_TRENDING_MEME,
  payload: data,
});

export const setMeme = (data) => ({
  type: actionType.SET_MEME,
  payload: data,
});


export const getMeme = (id) => {
  return (dispatch) => {

    axios
      .get(`/blog/meme-read/${id}`)
      .then((resp) => {
        console.log('READ MEME',resp.data);
        dispatch(setMeme(resp.data));
      })
      .catch((error) => {
         alert('Error')
      });
  };
};

export const getTrendingMeme = () => {
  return (dispatch) => {

    axios
      .get("/blog/meme-most-love")
      .then((resp) => {
        console.log('TREnDING MEME',resp.data);
        dispatch(setTrendingMeme(resp.data));
      })
      .catch((error) => {
         alert('Error')
      });
  };
};

export const fetchMeme = (type) => {
  return (dispatch) => {
    if (!type) {
      dispatch(setMemeLoading());
    }
    axios
      .get("/blog/admin-meme-list")
      .then((resp) => {
        console.log(resp.data);
        dispatch(setMemeSuccess(resp.data));
      })
      .catch((error) => {
        dispatch(setMemeFailed(error.response));
      });
  };
};

export const deleteMeme = (id) => {
  const Token = localStorage.getItem('token')
  return (dispatch) => {
    // dispatch(setMemeLoading())
    axios
      .delete("/blog/meme-delete/" + id,
      {
        headers: {
            Authorization: `JWT ${Token}`
        }
    })
      .then((resp) => {
        console.log(resp.data);
        dispatch(fetchMeme(1));
      })
      .catch((error) => {
        dispatch(setMemeFailed(error.response.data));
      });
  };
};

export const createMeme = (data) => {
  const Token = localStorage.getItem('token')
  console.log("data", data);
  const fd = new FormData();
  fd.append("title", data.title);
  fd.append("descriptions", data.descriptions);
  fd.append("image", data.file);
  fd.append("tags", data.tags);
  fd.append("author_name", data.author);
  fd.append("is_enable", true); 
  fd.append("category", data.category);

  return (dispatch) => {
    dispatch(setMemeLoading());
    axios
      .post("/blog/meme-post", fd,
      {
        headers: {
            Authorization: `JWT ${Token}`
        }
    })
      .then((response) => {
        dispatch(setMemeMSG("Meme Created Succesfull"));
      })
      .catch((error) => {
        dispatch(setMemeAddingFailed(error.response));
      });
  };
};

export const updateMeme = (id, data, token) => {
  const Token = localStorage.getItem('token')
console.log('Tokwn',token)
  const fd = new FormData();
  console.log('DATATA',data.is_enable);
  // fd.append("id",id)
  if(data.title){
    fd.append("title", data.title);
  }

  if(data.descriptions){
    fd.append("descriptions", data.descriptions);
  }

  // if(data.file){
  //   fd.append("image", data.file);
  // }

  if(data.tags){
    fd.append("tags", data.tags);
  }

  if(data.author){
    fd.append("author_name", data.author);
  }

  if(data.category){
    fd.append("category", data.category);
  }

  // if(Object.keys['is_enable']){
   

    if(data.is_enable !== undefined){
      fd.append("is_enable", data.is_enable);
    }

  if(data.is_enable){
    
  }
 

  return (dispatch) => {
    dispatch(setMemeLoading());
    axios
      .patch("/blog/meme-patch/" + id, fd,{
        headers: {
            Authorization: `JWT ${Token}`
        }
    })
      .then((resp) => {
        if(data.is_enable == undefined){
          dispatch(setMemeMSG("Meme Update Succesfull"));
        }
        dispatch(fetchMeme(1));
      })
      .catch((error) => {
        dispatch(setMemeAddingFailed(error.response.data));
      });
  };
};
