import React, { useState } from 'react'
import axios from 'axios'
import Alert from '../Components/Alert/alert.component'

import { logOut, setToken } from '../redux/user/user.action'
import { useDispatch } from 'react-redux'

export const ChangePassword = ({history}) => {
    const [oldPass, setOldPass] = useState('')
    const [newPass, setNewPass] = useState('')
    const [cnfPass, setCnfPass] = useState('')
    const [msg, setMsg] = useState('')
    const dispatch = useDispatch()

    const sumbitHAndler = (e) => {
        e.preventDefault()
        const Token = localStorage.getItem('token')
        axios.put('/authentication/admin-change-password',
            { old_password: oldPass, new_password: newPass },
            {
                headers: {
                    Authorization: `JWT ${Token}`
                }
            }
        ).then(resp => {
            setMsg('Your password updated successfully')
            setTimeout(() => {
                localStorage.clear()
                history.push('/')
                dispatch(logOut())
            }, 1000)
        }).catch((error) => {
            let errorKey = Object.keys(error.response.data)
            let msgKey = Object.values(error.response.data)
            console.log(errorKey[0] + msgKey[0])
            console.log(Object.keys(error.response.data))
            setMsg(`${errorKey[0]} :  ${msgKey[0]}`)
        }
        )
    }

    return (
        <>
            <div className="container d-flex flex-column align-items-center p-4">
                <h4>Change Password</h4>
                <div className="col-md-6" >
                    <form onSubmit={sumbitHAndler} >
                        <input type="password" className="form-control mt-2" value={oldPass} onChange={(e) => setOldPass(e.target.value)} placeholder="Old Password" required />
                        <input type="password" className="form-control mt-2" value={newPass} onChange={(e) => setNewPass(e.target.value)} minLength="6" placeholder="New Password" required />
                        <input type="text" className="form-control mt-2" value={cnfPass} onChange={(e) => setCnfPass(e.target.value)} minLength="6" placeholder="Confirm new Password" required />
                        {newPass !== cnfPass && <p className="mt-1 text-danger" >New password and Confirm Password is not match</p>}
                        <div className="text-center mt-2" >
                            <button type="submit" className="btn btn-warning" disabled={newPass !== cnfPass} >Submit</button>
                        </div>
                    </form>
                </div>
            </div>
            <Alert open={msg !== ''} close={() => setMsg('')} type="alert" message={msg} />
        </>
    )
}