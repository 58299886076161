import React,{ useEffect, useState} from 'react'
import Sidebar from '../Components/Sidebar'
import { Tabs, Tab, Row, Col, Container, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import TrendingTable from '../Components/Table/trending.table'
import { fetchCategory, deleteCategory, togleCategory } from '../redux/category/category.action'
import { getTrendingMeme} from '../redux/meme/meme.action'
import { categoryListSelector, categoryToggleSelector } from '../redux/category/category.selector'

function Dashboard() {

    const Dispatch = useDispatch()
    const {trendingMeme} = useSelector(state => state.meme)

    useEffect(() => {
        Dispatch(getTrendingMeme())
    }, [])

    const categoryList = useSelector(state => categoryListSelector(state))
  
    return (
        <>
        <Row className="p-2" style={{background: 'black'}}>
            <Col className="mr-3">
                <h5 className="text-white">Most Liked Memes</h5>
            </Col>
            {/* <Col md="2">
                <Button variant="secondary" onClick={() => Dispatch(togleCategory())} >Add Category</Button>
            </Col> */}
        </Row>
        <TrendingTable tableData={trendingMeme}/>

   
    </>
    )
}

export default Dashboard
