import React, { useEffect, useState } from 'react'
import { Tabs, Tab, Row, Col, Container, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { fetchMeme, deleteMeme } from '../redux/meme/meme.action'
import { memeListSelector } from '../redux/meme/meme.selector'
import MemeTable from '../Components/Table/meme.table'
import { CategoryForm } from '../containers/Category.container'
import Alert from '../Components/Alert/alert.component'

const Pending = ({ history }) => {
    const [deletId, setDeleteId] = useState(null)
    const Dispatch = useDispatch()
    const memeList = useSelector(state => memeListSelector(state))

    let filtered = memeList.filter(meme => meme.is_enable == false)

    useEffect(() => {
        Dispatch(fetchMeme())
    }, [])

    const editHandler = (id) => {
        Dispatch({ type: 'CLEAR_MEME_MSG', payload: id })
    }
    const deleteHandler = (id) => {
        setDeleteId(id)
        // Dispatch(deleteCategory(id))
    }
    return (
        // <Container>
        <>
            <Row className="p-2" style={{background: 'black'}}>
                <Col className="mr-3">
                    <h5 className="text-white">Meme</h5>
                </Col>
                <Col md="2">
                    <Button variant="warning" onClick={() => history.push('/upload-meme')} >Add Meme</Button>
                </Col>
            </Row>
            <MemeTable tableData={filtered} onEdit={(id)=>history.push(`/edit-meme/${id}`)} OnDelete={deleteHandler} />
            <Alert open={deletId} message={"Do You want to delete Meme !"}
                close={() => setDeleteId(null)} confirm={() => {
                    Dispatch(deleteMeme(deletId))
                    setDeleteId(null)
                }} />
        </>
        // </Container>
    );
}
export default Pending