export const CATEGORY_LIST_LOADING  = 'CATEGORY_LIST_LOADING'
export const CATEGORY_LIST_SUCCESS  = 'CATEGORY_LIST_SUCCESS'
export const CATEGORY_LIST_FAILED  = 'CATEGORY_LIST_FAILED'

export const UPDATE_CATEGORY = "UPDATE_CATEGORY"

export const TOGGLE_CATEGORY = "TOGGLE_CATEGORY"
export const CATEGORY_MSG = "CATEGORY_MSG"
export const CATEGORY_ADDING_ERROR = "CATEGORY_ADDING_ERROR"



 