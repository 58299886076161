

import React from 'react'
import DataTable from 'react-data-table-component';
import { Button } from 'react-bootstrap'
import { BsJustify } from 'react-icons/bs';
import {MAINURL} from '../../index'
import { Link } from 'react-router-dom';



const TrendingTable = ({ tableData}) => {



    let columns = []
    let key = tableData.length > 0 ? Object.keys(tableData[0]) : []

    if (key.length > 0) {
        key.map(list => {
            if (list !== "category" && list !== "is_enable" && list !== "descriptions" && list !== "updated_at"&& list !== "image" && list !== "user_id") {
                columns.push({
                    name: list.toUpperCase(),
                    selector: list
                })
            }
             else if (list == "image") {
                columns.push({
                    name: list.toUpperCase(),
                    cell: row => <img height="60" width="60" src={`${MAINURL}${row.image}`} />
                    
                })
            }
        })
        columns.push({
            name: 'View',
            cell: row =>  <Link className="btn btn-warning" to={`/view-meme/${row.id}`}> View</Link>
            
        })
    }

    

    return (
        <DataTable
            columns={columns}
            data={tableData}
            // dense={true}
            striped={true}
            responsive={true}
        />
    )
}

export default TrendingTable