import * as actionType from './category.type'

const initialState = {
    loading: false,
    data: [],
    success: null,
    loadingError: null,
    error: null,
    updateID: null,
    toggle: false
}

export const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.TOGGLE_CATEGORY:
            return {
                ...state,
                toggle: !state.toggle
            }
        case actionType.CATEGORY_LIST_LOADING:
            return {
                ...state,
                loading: true,
                loadingError: null,
                updateID: null
            }
        case actionType.UPDATE_CATEGORY:
            return {
                ...state,
                updateID: action.payload,
                toggle: !state.toggle
            }
        case actionType.CATEGORY_LIST_SUCCESS:
            return {
                ...state,
                loadingError: false,
                data: action.payload
            }
        case actionType.CATEGORY_LIST_FAILED:
            return {
                ...state,
                loadingError: false,
                data: [],
                error: action.payload
            }
        case actionType.CATEGORY_MSG:
            return {
                ...state,
                success: action.payload,
                toggle: !state.toggle
            }
        case actionType.CATEGORY_ADDING_ERROR:
            return {
                ...state,
                error: action.payload,
                toggle: !state.toggle
            }
        case "CLEAR_CAT_MSG":
            return {
                ...state,
                success: null,
                error: null,
            }

    }
    return state
}