import React,{useState,useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {useSelector,useDispatch} from 'react-redux'
import { MAINURL } from '..'
import { getMeme } from '../redux/meme/meme.action'
import { Col, Row } from 'react-bootstrap'

const ViewMeme = () => {
    const dispatch = useDispatch()
    const {data} = useSelector(state => state.meme)
    const {meme} = useSelector(state => state.meme)
    const {id} = useParams()
    console.log('DATA',data);


    useEffect(() => {
        // let filteredMeme = data.find((meme) => meme.id == id)
        dispatch(getMeme(id))

    },[])

    function stripHtml(html){
        // Create a new div element
        var temporalDivElement = document.createElement("span");
        // Set the HTML content with the providen
        temporalDivElement.innerHTML = html;
        // Retrieve the text property of the element (cross-browser support)
        return temporalDivElement.textContent || temporalDivElement.innerText || "";
    }


    return (
       
        <div className="meme-container">
            {/* {console.log('title',meme.category.name)} */}
            <h3>{meme.title}</h3>
           
            <span>Created by: {meme.author_name}</span><br></br>
            {/* <span>Created at: {meme.category.name}</span><br></br> */}
            <span>Created at: {meme.created_at}</span>
            <div className="meme-image">
                <img className="img-fluid" src={`${MAINURL}${meme.image}`}></img>
                <p>Description:  {stripHtml(meme.descriptions)}</p>
            </div>
        </div>
    )
}

export default ViewMeme

