import React from "react";
import { FaUserAlt } from "react-icons/fa";
import { BsLockFill } from "react-icons/bs";
import {setUsername,setPassword,setToken,login} from "../redux/user/user.action"
import {useDispatch,useSelector} from 'react-redux'

export const Login = () => {

  const dispatch = useDispatch()
  const {username, password, token} = useSelector(state => state.userReducer)

  console.log('UserName', username);
  console.log('Password', password);

  return (
    <div className="login-container">
      <form className="login" onSubmit={(e) =>{
           e.preventDefault()
          dispatch(login(username,password))
      }}>
        <h2>Login to your account</h2>
        <label>Username</label>
        <div class="input-group mb-3">
          {/* <label>Username</label>               */}
          <span class="input-group-text" id="basic-addon1">
            <FaUserAlt className="icon" />
          </span>
          <input
            type="text"
            class="form-control"
            placeholder="Username"
            aria-label="Username"
            aria-describedby="basic-addon1"
            value={username}
            onChange={(e) => dispatch(setUsername(e.target.value))}
          ></input>
        </div>
        <label>Password</label>
        <div class="input-group mb-4">
          <span class="input-group-text" id="basic-addon1">
            <BsLockFill className="icon" />
          </span>
          <input
            type="password"
            class="form-control"
            placeholder="Password"
            aria-label="Username"
            aria-describedby="basic-addon1"
            value={password}
            onChange={(e) => dispatch(setPassword(e.target.value))}
          ></input>
         
        </div>
        {/* <a className="forgot-password" href="#">Forgot your password?</a> */}
        <div>
          <button type="submit" class="form-control">Log In</button>
        </div>
      </form>

    </div>
  );
};
