export const MEME_LIST_LOADING  = 'MEME_LIST_LOADING'
export const MEME_LIST_SUCCESS  = 'MEME_LIST_SUCCESS'
export const MEME_LIST_FAILED  = 'MEME_LIST_FAILED'

export const UPDATE_MEME = "UPDATE_MEME"

export const MEME_MSG = "MEME_MSG"
export const MEME_ADDING_ERROR = "MEME_ADDING_ERROR"

export const SET_TRENDING_MEME = "SET_TRENDING_MEME"
export const SET_MEME = "SET_MEME"
export const GET_MEME = "GET_MEME"



 