import axios from 'axios'
import * as actionType from './category.type'

export const togleCategory = () => ({
    type: actionType.TOGGLE_CATEGORY
})

const setCategoryLoading = () => ({
    type: actionType.CATEGORY_LIST_LOADING
})

const setCategorySuccess = (data) => ({
    type: actionType.CATEGORY_LIST_SUCCESS,
    payload: data
})

const setCategoryFailed = (error) => ({
    type: actionType.CATEGORY_LIST_FAILED,
    payload: error
})

const setCategoryMSG = (msg) => ({
    type: actionType.CATEGORY_MSG,
    payload: msg
})

const setCategoryAddingFailed = (error) => ({
    type: actionType.CATEGORY_ADDING_ERROR,
    payload: error
})

export const fetchCategory = (type) => {
    return dispatch => {
        if (!type) {
            dispatch(setCategoryLoading())
        }
        axios.get('/blog/category-list')
            .then(resp => {
                console.log(resp.data)
                dispatch(setCategorySuccess(resp.data))
            }).catch((error) => {
                dispatch(setCategoryFailed(error.response))
            })
    }
}

export const deleteCategory = (id) => {
    const Token = localStorage.getItem('token')
    return dispatch => {
        // dispatch(setCategoryLoading())
        axios.delete('/blog/category-delete/' + id,
            {
                headers: {
                    Authorization: `JWT ${Token}`
                }
            })
            .then(resp => {
                console.log(resp.data)
                dispatch(fetchCategory(1))
            }).catch((error) => {
                dispatch(setCategoryFailed(error.response.data))
            })
    }
}
// http://5.189.166.187:8037/

export const createCategory = (name, token) => {
    const Token = localStorage.getItem('token')
    return dispatch => {
        dispatch(setCategoryLoading())
        axios.post('/blog/category-post', { name: name },
            {
                headers: {
                    Authorization: `JWT ${Token}`
                }
            })
            .then(resp => {
                dispatch(setCategoryMSG("Category Created Succesfull"))
                dispatch(fetchCategory())

            }).catch((error) => {
                if (error.response.data.name) {
                    dispatch(setCategoryAddingFailed(error.response.data['name']))
                } else {
                    dispatch(setCategoryAddingFailed('something went wrong'))
                }
            })
    }
}

export const updateCategory = (id, name, token) => {
    const Token = localStorage.getItem('token')
    return dispatch => {
        dispatch(setCategoryLoading())
        axios.patch('/blog/category-patch/' + id, { name: name },
            {
                headers: {
                    Authorization: `JWT ${Token}`
                }
            })
            .then(resp => {
                dispatch(setCategoryMSG("Category Update Succesfull"))
                dispatch(fetchCategory(1))
            }).catch((error) => {
                dispatch(setCategoryAddingFailed(error.response.data))
            })
    }
}


