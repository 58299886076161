import React, { useEffect, useState } from 'react'
import Modal from '../Components/Modal/model.component'
import { Tabs, Tab, Row, Col, Container, Button, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { createCategory, updateCategory } from '../redux/category/category.action'
import { categoryMessageSelector, categoryListSelector, categoryUpdateSelector } from '../redux/category/category.selector'
import Alert from '../Components/Alert/alert.component'

export const CategoryForm = ({ show, onClose }) => {
    const [catName, setCatName] = useState('')
    const Dispatch = useDispatch()
    const categoryList = useSelector(state => categoryListSelector(state))
    const categoryMSG = useSelector(state => categoryMessageSelector(state))
    const categoryUpdate = useSelector(state => categoryUpdateSelector(state))

    useEffect(() => {
        if (categoryList && categoryUpdate) {
            let Array = categoryList.find((list) => list.id === categoryUpdate)
            setCatName(Array.name)
        }
    }, [categoryUpdate, categoryList])

    const onSubmitHandler = (e) => {
        e.preventDefault()
        if(!categoryUpdate){
        Dispatch(createCategory(catName))
        setCatName('')
        }else{
        Dispatch(updateCategory(categoryUpdate,catName))
        }
    }

    return (
        <>
            <Modal show={show}
                heading="title"
                onHide={onClose}
            >
                <Form onSubmit={onSubmitHandler} >
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Category Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter Category Name"
                            value={catName}
                            onChange={(e) => setCatName(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Col className="text-center mt-3">
                        <Button variant="warning" type="submit">
                            Submit
  </Button>
                    </Col>
                </Form>
            </Modal>
            <Alert type="alert" open={categoryMSG} message={categoryMSG}
                close={() => Dispatch({ type: 'CLEAR_CAT_MSG' })} />
        </>

    )
}